import { Spinner } from "react-bootstrap";
import "./spinner-sequence.css";

const FullPageSpinner = () => (
    <div
        className="d-flex justify-content-center align-items-center spinner-sequence"
        style={{ minHeight: "100vh" }}
    >
        <Spinner animation="grow" variant="primary" className="mx-2" size="sm"/>
        <Spinner animation="grow" variant="primary" className="mx-2" size="sm"/>
        <Spinner animation="grow" variant="primary" className="mx-2" size="sm"/>
    </div>
);

export default FullPageSpinner;